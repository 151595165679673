import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Dialog } from 'primereact/dialog';
import { Accordion, AccordionTab } from 'primereact/accordion';
import gpt3Logo from '../../logo.svg';
import './footer.css';

const Footer = () => {
  const [visible, setVisible] = useState(false);
  const [tncvisible, setVisibletnc] = useState(false);
  const handleWhatsAppClick = () => {
    window.open('https://api.whatsapp.com/send?phone=+917489017551', '_blank');
  };
  useEffect(() => {
    setVisible(visible);
  }, [visible]);
  useEffect(() => {
    setVisibletnc(tncvisible);
  }, [tncvisible]);
  return (
    <footer className="gpt3__footer section__padding">
      <Helmet>
        <title>Top Web Design &amp; Development Company in India</title>
        <meta name="description" content="Algodoor is a leading IT services and product company providing digital solutions for software development to clients globally. We specialize in Healthcare, Financial Services, Automobile, and Chatbots. Explore the possibilities beyond your imagination." />
        <meta name="keywords" content="IT services, Madhya Pradesh, top it companies in madhya pradesh, best it companies in india for freshers, best it companies, best it companies for freshers, best it companies in indore, Top IT Companies In Indore India, web development, software development, digital transformation, best IT company, Algodoor, IT services, software development, Healthcare, Financial Services, Automobile, Chatbots" />
        {/* Add other necessary meta tags for SEO */}
      </Helmet>

      <div className="gpt3__footer-heading">
        <h2 className="gradient__text">Do you want to step into the future before others?</h2>
      </div>

      <div className="gpt3__footer-btn">
        <p>Door to Algorithm</p>
      </div>

      <div className="gpt3__footer-links">
        <div className="gpt3__footer-links_logo">
          <img src={gpt3Logo} alt="AlgoDoor Logo" />
          <p>&#174; Registered under the Ministry of MSME, India</p>
        </div>
        <nav className="gpt3__footer-links_nav">
          <h3>Links</h3>
          <ul>
            <li>
              <div style={{ display: 'flex' }}>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a onClick={handleWhatsAppClick}> <i style={{ 'font-size': '1.3em', marginRight: '10px' }} className="fab fa-whatsapp" aria-hidden="true" /> </a>
                {/* <a href="https://www.linkedin.com/company/96063255/"> <i style={{ 'font-size': '1.3em', marginRight: '10px' }} className="fab fa-linkedin" aria-hidden="true" /> </a>
                <a href="https://www.facebook.com/profile.php?id=100094085550925"> <i style={{ 'font-size': '1.3em', marginRight: '10px' }} className="fab fa-facebook-square" aria-hidden="true" /> </a>
                <a href="https://www.instagram.com/keshu.arora/"> <i style={{ 'font-size': '1.3em', marginRight: '10px' }} className="fab fa-instagram" aria-hidden="true" /> </a> */}
              </div>
            </li>
          </ul>
        </nav>
        <nav className="gpt3__footer-links_nav">
          <h3>Company</h3>
          <ul>
            <div className="card flex justify-content-center">
              <Dialog header="Company Policies" visible={visible} style={{ width: '50vw' }} breakpoints={{ '1440px': '40vw', '1024px': '60vw', '425px': '80vw' }} onHide={() => setVisible(false)}>
                <p className="mb-5">
                  <b>Equal Opportunity:</b>  Algodoor is committed to providing equal opportunities to all employees, regardless of their race, gender, age, religion, or any other protected characteristic. We foster an inclusive work environment where diversity is celebrated and everyone is treated with respect and fairness.
                </p>
                <p className="mb-5">
                  <b>Code of Conduct:</b>  We maintain a high standard of professionalism and ethical conduct in all business activities. Employees are expected to adhere to the company&apos; code of conduct, which promotes integrity, honesty, and accountability in their interactions with colleagues, clients, and stakeholders.
                </p>
                <p className="mb-5">
                  <b>Confidentiality and Data Security:</b>  We prioritize the confidentiality and security of sensitive information. Employees are required to handle company and client data responsibly, ensuring its protection from unauthorized access, disclosure, or misuse. Compliance with relevant data protection regulations is mandatory.
                </p>
                <p className="mb-5">
                  <b>Workplace Health and Safety:</b>  Algodoor is committed to providing a safe and healthy work environment for all employees. We comply with applicable health and safety regulations and take proactive measures to prevent accidents, injuries, and occupational hazards. Employees are encouraged to report any safety concerns promptly.
                </p>
                <p className="mb-5">
                  <b>Work-Life Balance:</b>  We value work-life balance and recognize the importance of personal well-being. Algodoor promotes flexible work arrangements, allowing employees to achieve a harmonious balance between their professional and personal lives. We encourage open communication and provide support to maintain a healthy work-life integration.
                </p>
                <p className="mb-5">
                  <b>Professional Development:</b>  We foster a culture of continuous learning and growth. Algodoor encourages employees to enhance their skills and knowledge through training programs, workshops, and professional development opportunities. We believe in empowering our employees to reach their full potential.
                </p>
                <p className="mb-5">
                  <b>Harassment-Free Workplace:</b>  Algodoor has zero tolerance for any form of harassment, including but not limited to sexual harassment, discrimination, or bullying. We maintain a respectful and inclusive work environment, where all individuals are treated with dignity. Complaints are taken seriously, and appropriate action is promptly taken.
                </p>
                <p className="mb-5">
                  <b>Remote Work Policy:</b>  Algodoor embraces remote work, providing employees with the flexibility to work from home or other locations. We ensure that remote employees have the necessary resources, communication tools, and support to perform their tasks effectively. Regular check-ins and virtual collaboration platforms facilitate seamless remote collaboration.
                </p>
              </Dialog>
            </div>
            <div className="card flex justify-content-center">
              <Dialog header="Terms & Conditions" visible={tncvisible} style={{ width: '50vw' }} breakpoints={{ '1440px': '40vw', '1024px': '60vw', '425px': '80vw' }} onHide={() => setVisibletnc(false)}>
                <Accordion activeIndex={0}>
                  <AccordionTab header="Service Agreement">
                    <ul className="m-0">
                      <li>1.1. The Services provided by Algodoor include software services, web design and development, e-commerce solutions, financial services, and healthcare solutions.</li>
                      <li>1.2. The scope of work, deliverables, and timelines will be mutually agreed upon between Algodoor and the Client.</li>
                      <li>1.3. The Client acknowledges that delays in providing required information, feedback, or approvals may impact project timelines.</li>
                    </ul>
                  </AccordionTab>
                  <AccordionTab header="Intellectual Property">
                    <ul className="m-0">
                      <li>2.1. All intellectual property rights related to the deliverables created by Algodoor during the course of the project will belong to the Client upon full payment.</li>
                      <li>2.2. Algodoor retains the right to showcase the completed work for promotional purposes, unless otherwise agreed with the Client.</li>
                    </ul>
                  </AccordionTab>
                  <AccordionTab header="Payment Terms">
                    <ul className="m-0">
                      <li>3.1. The Client agrees to pay the fees as specified in the project proposal or agreement.</li>
                      <li>3.2. Payment terms, including milestones and due dates, will be outlined in the project proposal or agreement.</li>
                      <li>3.3. In the event of late payment, Algodoor reserves the right to suspend or terminate the Services.</li>
                    </ul>
                  </AccordionTab>
                  <AccordionTab header="Confidentiality">
                    <ul className="m-0">
                      <li>4.1. Algodoor and the Client agree to keep all confidential information shared during the project confidential.</li>
                      <li> 4.2. Confidential information includes but is not limited to proprietary business information, trade secrets, and customer data.</li>
                    </ul>
                  </AccordionTab>
                  <AccordionTab header="Limitation of Liability">
                    <ul className="m-0">
                      <li>5.1. Algodoor will make reasonable efforts to ensure the accuracy and quality of the Services. However, Algodoor will not be liable for any damages, losses, or expenses arising from the use of or inability to use the Services.</li>
                      <li> 5.2. The Client agrees to indemnify and hold Algodoor harmless from any claims, liabilities, or expenses arising from the Client&apos;s use of the Services.</li>
                    </ul>
                  </AccordionTab>
                  <AccordionTab header="Termination">
                    <ul className="m-0">
                      <li>6.1. Either party may terminate the Services with written notice if the other party breaches any material term of this Agreement.</li>
                      <li>6.2. Upon termination, the Client will pay for any Services rendered up to the termination date.</li>
                    </ul>
                  </AccordionTab>
                  <AccordionTab header="Governing Law">
                    <ul className="m-0">
                      <li>7.1. This Agreement will be governed by and interpreted in accordance with the laws of the jurisdiction where Algodoor is registered.</li>
                    </ul>
                  </AccordionTab>
                  <AccordionTab header="Amendments">
                    <ul className="m-0">
                      <li>8.1. Algodoor reserves the right to modify these Terms and Conditions at any time. The Client will be notified of any changes, and continued use of the Services will indicate acceptance of the modified terms.</li>
                    </ul>
                  </AccordionTab>
                </Accordion>
              </Dialog>
            </div>
            <li><div style={{ color: 'white' }} onClick={() => setVisible(true)} aria-label="Company Policies">Company Policies </div>
            </li>
            <li><div style={{ color: 'white' }} onClick={() => setVisibletnc(true)} aria-label="Company Policies">Terms &amp; Conditions </div></li>
            <li><a href="#contactus">Contact</a></li>
          </ul>
        </nav>
        <div className="gpt3__footer-links_div">
          <h3>Get in touch</h3>
          <address>
            <p>Bangalore, Karnataka, India</p>
            <p><a href="tel:+917489017551">+91-7489017551</a></p>
            <p><a href="mailto:info@algodoor.com">info@algodoor.com</a></p>
          </address>
        </div>
      </div>

      <div className="gpt3__footer-copyright">
        <p>&copy; 2018 AlgoDoor. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
