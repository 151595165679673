/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import './modal.css';

const Modal = ({ closeModal }) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [contact, setContact] = useState('');
  const [message, setMessage] = useState('');
  const [isFormValid, setIsFormValid] = useState(false);
  const [isNameDirty, setIsNameDirty] = useState(false);
  const [isEmailDirty, setIsEmailDirty] = useState(false);
  const [isContactDirty, setIsContactDirty] = useState(false);
  const [isMessageDirty, setIsMessageDirty] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();

    // Replace with your EmailJS service ID
    const serviceId = 'service_wzcrsxp';
    // Replace with your EmailJS template ID
    const templateId = 'template_aszhm1n';
    // Replace with your EmailJS user ID
    const userId = '7lTaAJbQz06mpRC3i';

    const templateParams = {
      from_name: name,
      from_email: email,
      contact_number: contact,
      message,
    };

    emailjs
      .send(serviceId, templateId, templateParams, userId)
      .then((response) => {
        alert('Query sent successfully!', response);
        // Reset form fields
        setName('');
        setEmail('');
        setContact('');
        setMessage('');
        closeModal();
      })
      .catch((error) => {
        console.error('Email sending failed:', error);
      });
  };

  const validateForm = () => {
    const isNameValid = name.length >= 3 && name.length <= 50;
    const isEmailValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    const isContactValid = /^[\d+]+$/.test(contact) && contact.length >= 10 && contact.length <= 12;
    const isMessageValid = message.length >= 10 && message.length <= 500;

    setIsFormValid(isNameValid && isEmailValid && isContactValid && isMessageValid);
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
    setIsNameDirty(true);
    validateForm();
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setIsEmailDirty(true);
    validateForm();
  };

  const handleContactChange = (e) => {
    const inputValue = e.target.value;
    if (/^[\d+]*$/.test(inputValue)) {
      setContact(inputValue);
      setIsContactDirty(true);
      validateForm();
    }
  };

  const handleMessageChange = (e) => {
    setMessage(e.target.value);
    setIsMessageDirty(true);
    validateForm();
  };

  const isNameInvalid = isNameDirty && (name.length < 3 || name.length > 50);
  const isEmailInvalid = isEmailDirty && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  const isContactInvalid = isContactDirty && (!/^[\d+]+$/.test(contact) || contact.length < 10 || contact.length > 12);
  const isMessageInvalid = isMessageDirty && (message.length < 10 || message.length > 500);

  return (
    <div className="modal">
      <div className="modal-content">
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="name">Name:</label>
            <input
              type="text"
              id="name"
              value={name}
              onChange={handleNameChange}
              minLength={3}
              maxLength={50}
              required
            />
            {isNameInvalid && <p className="warning">Please enter a valid name.</p>}
          </div>

          <div className="form-group">
            <label htmlFor="email">Email:</label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={handleEmailChange}
              pattern="[^\s@]+@[^\s@]+\.[^\s@]+"
              required
            />
            {isEmailInvalid && <p className="warning">Please enter a valid email address.</p>}
          </div>

          <div className="form-group">
            <label htmlFor="contact">Contact Number:</label>
            <input
              type="tel"
              id="contact"
              value={contact}
              onChange={handleContactChange}
              minLength={10}
              maxLength={12}
              required
            />
            {isContactInvalid && <p className="warning">Please enter a valid contact number.</p>}
          </div>

          <div className="form-group">
            <label htmlFor="message">Message:</label>
            <textarea
              id="message"
              value={message}
              onChange={handleMessageChange}
              cols={55}
              rows={10}
              style={{ resize: 'vertical' }}
              minLength={10}
              maxLength={500}
              required
            />
            {isMessageInvalid && (
              <p className="warning">Please enter a message between 10 and 500 characters.</p>
            )}
          </div>

          <div className="form-buttons">
            <button type="submit" disabled={!isFormValid}>
              Send Message
            </button>
            {
              closeModal ? (
                <button type="button" onClick={closeModal}>
                  Cancel
                </button>
              ) : null
            }

          </div>
        </form>
      </div>
    </div>
  );
};

export default Modal;
