import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { RiMenu3Line, RiCloseLine } from 'react-icons/ri';
import { sendMail } from '../../sendMail';
import './navbar.css';
import logo from '../../logo.svg';
import Modal from '../modal/modal';
import instagramLogo from '../../assets/instagram.svg';

const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);

  const toggleMenuHandler = () => {
    setToggleMenu(!toggleMenu);
  };

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    sendMail();
  };

  const handleOutsideClick = (e) => {
    if (e.target.classList.contains('modal')) {
      closeModal();
    }
  };

  return (
    <nav className="gpt3__navbar" aria-label="Main Navigation">
      <Helmet>
        <title>Top Web Design &amp; Development Company in India</title>
        <meta name="description" content="Algodoor is a leading IT services and product company providing digital solutions for software development to clients globally. We specialize in Healthcare, Financial Services, Automobile, and Chatbots. Explore the possibilities beyond your imagination." />
        <meta name="keywords" content="IT services, Madhya Pradesh, top it companies in madhya pradesh, best it companies in india for freshers, best it companies, best it companies for freshers, best it companies in indore, Top IT Companies In Indore India, web development, software development, digital transformation, best IT company, Algodoor, IT services, software development, Healthcare, Financial Services, Automobile, Chatbots" />
        {/* Add other necessary meta tags for SEO */}
      </Helmet>
      <div className="gpt3__navbar-links">
        <div className="gpt3__navbar-links_logo">
          <img src={logo} alt="Logo" style={{ width: '100%', height: 'auto' }} />
        </div>
        <div className="gpt3__navbar-links_container">
          <p><a href="#home">Home</a></p>
          {/* <p><a href="#possibility" onClick={() => false}>Products/Services</a></p> */}
          <p><a href="#features" onClick={() => false}>Products/Services</a></p>
          <p><a href="#wgpt3" onClick={() => false}>What is AlgoDoor?</a></p>
          <p><a href="#contactus">Contact Us</a></p>
          <p><a href="#portfolio">Portfolio</a></p>
          {/* <p><a href="#uilib">UI Library</a></p> */}

          {/* <p><a href="#blog">Library</a></p> */}
        </div>
      </div>
      <div className="gpt3__navbar-sign">
        {/* <i className="fa fa-brands fa-instagram" style={{ color: '#ff234b' }} /> */}
        <img src={instagramLogo} alt="Instagram" style={{ width: '20px', height: '20px', marginRight: '5px' }} />
        <button key="modalBtn" type="button" onClick={openModal}>Get A Quote</button>
      </div>
      <div className="gpt3__navbar-menu">
        {toggleMenu
          ? <RiCloseLine color="#fff" size={27} onClick={toggleMenuHandler} />
          : <RiMenu3Line color="#fff" size={27} onClick={toggleMenuHandler} />}
        {toggleMenu && (
          <div className="gpt3__navbar-menu_container scale-up-center">
            <div className="gpt3__navbar-menu_container-links">
              <p><a href="#home">Home</a></p>
              <p><a href="#features">Products/Services</a></p>
              <p><a href="#wgpt3" onClick={() => false}>What is AlgoDoor?</a></p>
              <p><a href="#contactus">Contact Us</a></p>
              <p><a href="#portfolio">Portfolio</a></p>
              {/* <p><a href="#uilib">UI Library</a></p> */}

            </div>
            <div className="gpt3__navbar-menu_container-links-sign">
              <button key="modalBtn" type="button" onClick={openModal}>Get A Quote</button>
            </div>
          </div>
        )}
      </div>
      {isModalOpen && (
        <div className="modal-overlay" onClick={handleOutsideClick}>
          <Modal closeModal={closeModal} />
        </div>
      )}
    </nav>
  );
};

export default Navbar;
