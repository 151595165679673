import React from 'react';

import { Footer, Possibility, Features, WhatGPT3, Header } from './containers';
import { CTA, Brand, Navbar } from './components';

import './App.css';
import HorizontalRowWithMapAndModal from './containers/MapRow/maprow';
import Carousel from './components/carousel/carousel';

import 'primereact/resources/primereact.min.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';

const App = () => (
  <div className="App">
    <div className="gradient__bg">
      <Navbar />
      <Header />
    </div>
    <Brand />
    <WhatGPT3 />
    <Features />
    <Possibility />
    <CTA />
    <HorizontalRowWithMapAndModal />
    <Carousel />
    {/* <Blog /> */}
    {/* <UILibrary /> */}
    <Footer />
  </div>
);

export default App;
