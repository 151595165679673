import React from 'react';
import Querycard from '../../components/querycard/querycard';
import ind from '../../assets/ind.png';
import cnd from '../../assets/cnd.png';
import './maprow.css';
import '../features/features.css';

const GoogleMap = () => (
  <div className="google-map">
    <iframe title="Algodoor Technologies Bangalore" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3886.955006094373!2d77.6534307!3d13.038535999999999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae17c0b817865b%3A0xf75d1d70fe4ea288!2sAlgodoor%20Technologies!5e0!3m2!1sen!2sin!4v1687244417645!5m2!1sen!2sin" width="600" height="450" style={{ border: 0 }} allowFullScreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" />
  </div>
);

const HorizontalRowWithMapAndModal = () => (
  <div className="horizontal-row" id="contactus">
    <div className="left-side">
      <div className="contacts-section">
        <h2 className="section-heading">Contacts</h2>
        <div className="contactInfoRow">
          <section className="contact-info-left">
            <div>
              <img src={ind} alt="India logo" height={50} width={50} />
            </div>
            <div className="contact-item">
              <i className="fas fa-map-marker-alt" />
              <span className="contact-text">Bangalore, Karnataka</span>
            </div>
            <div className="contact-item">
              <i className="far fa-envelope-open" />
              <span className="contact-text"><a href="mailto:info@algodoor.com">info@algodoor.com</a></span>
            </div>
            <div className="contact-item">
              <i className="fas fa-phone-alt" />
              <span className="contact-text"><a href="tel:+917489017551">+91-7489017551</a></span>
            </div>
          </section>
          <section className="contact-info-right">
            <div>
              <img src={cnd} alt="Canada logo" height={50} width={50} />
            </div>
            <div className="contact-item">
              <i className="fas fa-map-marker-alt" />
              <span className="contact-text">Kitchener, ON, Canada</span>
            </div>
            <div className="contact-item">
              <i className="far fa-envelope-open" />
              <span className="contact-text"><a href="mailto:info@algodoor.com">info@algodoor.com</a></span>
            </div>
            <div className="contact-item">
              <i className="fas fa-phone-alt" />
              <span className="contact-text"><a href="tel:+15485770610">+91-7489017551</a></span>
            </div>
          </section>
        </div>
      </div>
      <GoogleMap />
    </div>
    <div className="right-side">
      <div className="section-heading">
        <h2 className="section-heading-text">Want to Connect ? Send your query.</h2>
      </div>
      <Querycard />
    </div>
  </div>
);

export default HorizontalRowWithMapAndModal;
