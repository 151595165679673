import React, { useState, useEffect } from 'react';
import './brand.css';
import { Helmet } from 'react-helmet';

const Brand = () => {
  const [google, setGoogle] = useState(null);
  const [slack, setSlack] = useState(null);
  const [atlassian, setAtlassian] = useState(null);
  const [dropbox, setDropbox] = useState(null);
  const [shopify, setShopify] = useState(null);

  useEffect(() => {
    Promise.all([
      import('../../assets/google.webp'),
      import('../../assets/slack.webp'),
      import('../../assets/atlassian.webp'),
      import('../../assets/dropbox.webp'),
      import('../../assets/shopify.webp'),
    ]).then(([googleImg, slackImg, atlassianImg, dropboxImg, shopifyImg]) => {
      setGoogle(googleImg.default);
      setSlack(slackImg.default);
      setAtlassian(atlassianImg.default);
      setDropbox(dropboxImg.default);
      setShopify(shopifyImg.default);
    });
  }, []);

  return (
    <section className="gpt3__brand section__padding">
      <Helmet>
        <title>Top Web Design &amp; Development Company in India</title>
        <meta name="description" content="Algodoor is a leading IT services and product company providing digital solutions for software development to clients globally. We specialize in Healthcare, Financial Services, Automobile, and Chatbots. Explore the possibilities beyond your imagination." />
        <meta name="keywords" content="IT services, Madhya Pradesh, top it companies in madhya pradesh, best it companies in india for freshers, best it companies, best it companies for freshers, best it companies in indore, Top IT Companies In Indore India, web development, software development, digital transformation, best IT company, Algodoor, IT services, software development, Healthcare, Financial Services, Automobile, Chatbots" />
        {/* Add other necessary meta tags for SEO */}
      </Helmet>
      {google && (
        <div>
          <img src={google} alt="Google Logo" aria-label="Google" />
        </div>
      )}
      {slack && (
        <div>
          <img src={slack} alt="Slack Logo" aria-label="Slack" />
        </div>
      )}
      {atlassian && (
        <div>
          <img src={atlassian} alt="Atlassian Logo" aria-label="Atlassian" />
        </div>
      )}
      {dropbox && (
        <div>
          <img src={dropbox} alt="Dropbox Logo" aria-label="Dropbox" />
        </div>
      )}
      {shopify && (
        <div>
          <img src={shopify} alt="Shopify Logo" aria-label="Shopify" />
        </div>
      )}
    </section>
  );
};

export default Brand;
