import React from 'react';
import { Helmet } from 'react-helmet';
import Feature from '../../components/feature/Feature';
import './features.css';

const featuresData = [
  {
    title: 'Web Development',
    text: 'Harness the power of React, Angular, Node.js, and Java to build dynamic and scalable websites that provide an exceptional user experience across devices.',
  },
  {
    title: 'AI ChatGPT Chatbot Solutions',
    text: 'Integrate AI ChatGPT chatbot into websites and applications for personalized customer interactions and support.',
  },
  {
    title: 'E-commerce Development',
    text: 'Get a feature-rich and user-friendly e-commerce websites with seamless payment integration.',
  },
  {
    title: 'Enterprise Application Development',
    text: 'Design and develop tailored enterprise applications to streamline business processes and increase productivity.',
  },
  {
    title: 'Mobile Application Development',
    text: 'Create native Android/IOS applications with a focus on intuitive user interfaces and seamless performance.',
  },
  {
    title: 'IT Consulting and Support',
    text: 'Offer strategic technology consulting services to help businesses align their IT initiatives with their overall goals.',
  },
  {
    title: 'Social Media Marketing',
    text: "Enhance Your Brand's Online Presence and Drive Engagement with Effective Social Media Marketing Strategies.",
  },
  {
    title: 'University/College Projects',
    text: 'Customized College Projects, Full-Stack Development, Develop comprehensive college projects using a combination of front-end (Angular, ReactJS), back-end (Node.js, Java), and database technologies.',
  },
];

const Features = () => (
  <div className="gpt3__features section__padding" id="features">
    <Helmet>
      <title>Top Web Design &amp; Development Company in India</title>
      <meta name="description" content="Algodoor is a leading IT services and product company providing digital solutions for software development to clients globally. We specialize in Healthcare, Financial Services, Automobile, and Chatbots. Explore the possibilities beyond your imagination." />
      <meta name="keywords" content="IT services, Madhya Pradesh, top it companies in madhya pradesh, best it companies in india for freshers, best it companies, best it companies for freshers, best it companies in indore, Top IT Companies In Indore India, web development, software development, digital transformation, best IT company, Algodoor, IT services, software development, Healthcare, Financial Services, Automobile, Chatbots" />
      {/* Add other necessary meta tags for SEO */}
    </Helmet>
    <div className="gpt3__features-heading">
      <h1 className="gradient__text">Experience the Transformation with Our Premium Services.</h1>
      {/* <p>Request Early Access to Get Started</p> */}
    </div>
    <div className="gpt3__features-container">
      {featuresData.map((item, index) => (
        <Feature title={item.title} text={item.text} key={item.title + index} />
      ))}
    </div>
  </div>
);

export default Features;
