import React, { lazy, Suspense } from 'react';
import { Helmet } from 'react-helmet';
import possibilityImage from '../../assets/possibility1.webp';
import './possibility.css';

// Use lazy loading for the image component
const LazyImage = lazy(() => import('../LazyImage'));

const Possibility = () => (
  <section className="gpt3__possibility section__padding" id="possibility">
    <Helmet>
      <title>Top Web Design &amp; Development Company in India</title>
      <meta name="description" content="Algodoor is a leading IT services and product company providing digital solutions for software development to clients globally. We specialize in Healthcare, Financial Services, Automobile, and Chatbots. Explore the possibilities beyond your imagination." />
      <meta name="keywords" content="IT services, Madhya Pradesh, top it companies in madhya pradesh, best it companies in india for freshers, best it companies, best it companies for freshers, best it companies in indore, Top IT Companies In Indore India, web development, software development, digital transformation, best IT company, Algodoor, IT services, software development, Healthcare, Financial Services, Automobile, Chatbots" />
      {/* Add other necessary meta tags for SEO */}
    </Helmet>
    <div className="gpt3__possibility-image">
      {/* Lazy load the image */}
      <Suspense fallback={<div>Loading...</div>}>
        <LazyImage src={possibilityImage} alt="possibility" />
      </Suspense>
    </div>
    <div className="gpt3__possibility-content">
      {/* <h2>Request Early Access to Get Started</h2> */}
      <h1 className="gradient__text">AI-Powered Chatbot: Elevate Your Website&apos;s User Experience and Engagement</h1>
      <p>Algodoor&apos;s AI ChatGPT chatbot engages customers in personalized and intelligent conversations. By utilizing advanced natural language processing and machine learning algorithms, the chatbot understands customer queries and provides accurate and contextually relevant responses. This level of sophistication ensures a human-like interaction, enhancing customer satisfaction.</p>
      {/* <h2>Request Early Access to Get Started</h2> */}
    </div>
  </section>
);

export default Possibility;
