import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
// import { sendMail } from '../../sendMail';
// import Modal from '../../components/modal/modal';
import people from '../../assets/people.webp';
import ai from '../../assets/ai2.webp';
import './header.css';
// import '../../components/querycard/querycard.css';
import Modal from '../../components/modal/modal';

const Header = () => {
  useEffect(() => {
    const imageList = [ai, people];
    imageList.forEach((image) => {
      const img = new Image();
      img.src = image;
      img.setAttribute('loading', 'eager'); // Set loading attribute to "eager" for preloading
    });
  }, []);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleOutsideClick = (e) => {
    if (e.target.classList.contains('modal')) {
      closeModal();
    }
  };
  return (
    <div>

      <Helmet>
        <title>Top Web Design &amp; Development Company in India</title>
        <meta name="description" content="Algodoor is a leading IT services and product company providing digital solutions for software development to clients globally. We specialize in Healthcare, Financial Services, Automobile, and Chatbots. Explore the possibilities beyond your imagination." />
        <meta name="keywords" content="IT services, Madhya Pradesh, top it companies in madhya pradesh, best it companies in india for freshers, best it companies, best it companies for freshers, best it companies in indore, Top IT Companies In Indore India, web development, software development, digital transformation, best IT company, Algodoor, IT services, software development, Healthcare, Financial Services, Automobile, Chatbots" />
        {/* Add other necessary meta tags for SEO */}
      </Helmet>
      <div className="gpt3__header section__padding" id="home">
        <div className="gpt3__header-content">
          <h1 className="gradient__text">Let&apos;s Build Something amazing with AlgoDoor</h1>
          <div className="gpt3__header-content__input">
            <button key="modalBtn" type="button" onClick={openModal}>Get A Quote</button>
          </div>
          {isModalOpen && (
            <div className="modal-overlay" onClick={handleOutsideClick}>
              <Modal closeModal={closeModal} />
            </div>
          )}
          <div className="gpt3__header-content__people">
            <img src={people} alt="peoples" />
            <p>1600+ people requested a service in last 15 months</p>
          </div>
        </div>

        <div className="gpt3__header-image">
          <img src={ai} alt="AI Brand" />
        </div>
      </div>
    </div>
  );
};

export default Header;
