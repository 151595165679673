import React from 'react';
import './cta.css';
import { Helmet } from 'react-helmet';

const CTA = () => (
  <section className="gpt3__cta">
    <Helmet>
      <title>Top Web Design &amp; Development Company in India</title>
      <meta name="description" content="Algodoor is a leading IT services and product company providing digital solutions for software development to clients globally. We specialize in Healthcare, Financial Services, Automobile, and Chatbots. Explore the possibilities beyond your imagination." />
      <meta name="keywords" content="IT services, Madhya Pradesh, top it companies in madhya pradesh, best it companies in india for freshers, best it companies, best it companies for freshers, best it companies in indore, Top IT Companies In Indore India, web development, software development, digital transformation, best IT company, Algodoor, IT services, software development, Healthcare, Financial Services, Automobile, Chatbots" />
      {/* Add other necessary meta tags for SEO */}
    </Helmet>
    <div className="gpt3__cta-content">
      <p>Request Early to Get Discounted Services</p>
      <h3>Seamless Solutions, Just a Click Away: Book our services and enjoy a hassle-free experience.</h3>
    </div>
    <div className="gpt3__cta-btn">
      {/* Example of file upload, uncomment if needed */}
      {/* <div>
        <input type="file" accept="image/*" onChange={handleChange} aria-label="Upload File" />
        <button onClick={handleUpload} type="button">Upload to Firebase</button>
        <p>{percent}% done</p>
      </div> */}
      <button type="button"><a href="/#portfolio">See Portfolio</a></button>
    </div>
  </section>
);

export default CTA;

