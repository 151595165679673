const nodemailer = require('nodemailer');

const sendMail = () => {
  // Create a transporter using your email service configuration
  const transporter = nodemailer.createTransport({
    host: 'smtp.gmail.com',
    port: 587,
    secure: false,
    auth: {
      user: 'kashinatharora29@gmail.com',
      pass: 'fnzttxfyoishntvv',
    },
  });

  // Define the email content
  const mailOptions = {
    from: 'kashinatharora29@gmail.com',
    to: 'kashinatharora29@gmail.com',
    subject: 'Email Subject',
    text: 'Email Body',
  };

  // Send the email
  transporter.sendMail(mailOptions, (error, info) => {
    if (error) {
      console.error('Error sending query:', error);
    } else {
      // eslint-disable-next-line no-alert
      alert('Query sent:', info.response);
    }
  });
};

module.exports = {
  sendMail,
};
