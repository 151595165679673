import React, { useEffect, useState } from 'react';
import './article.css';
import { Helmet } from 'react-helmet';
import Mydialog from '../dialog/Dialog';

const Article = ({ imgUrl, date, text }) => {
  const [visible, setVisible] = useState(false);
  const [position, setPosition] = useState('center');
  const [header, setHeader] = useState('');
  const [content, setContent] = useState('');

  useEffect(() => {
    setPosition(position);
  }, [position]);

  const handleClick = (newHeader, newContent, pos, visibility) => {
    setHeader(newHeader);
    setContent(newContent);
    setVisible(visibility);
    setPosition(pos);
  };

  return (
    <article className="gpt3__blog-container_article">
      <Helmet>
        <title>Top Web Design &amp; Development Company in India</title>
        <meta name="description" content="Algodoor is a leading IT services and product company providing digital solutions for software development to clients globally. We specialize in Healthcare, Financial Services, Automobile, and Chatbots. Explore the possibilities beyond your imagination." />
        <meta name="keywords" content="IT services, Madhya Pradesh, top it companies in madhya pradesh, best it companies in india for freshers, best it companies, best it companies for freshers, best it companies in indore, Top IT Companies In Indore India, web development, software development, digital transformation, best IT company, Algodoor, IT services, software development, Healthcare, Financial Services, Automobile, Chatbots" />
        {/* Add other necessary meta tags for SEO */}
      </Helmet>
      <div className="gpt3__blog-container_article-image">
        <div className="gpt3__blog-container_article-image-inner">
          <img src={imgUrl} alt="blog_image" loading="lazy" />
        </div>
      </div>
      <div className="gpt3__blog-container_article-content">
        <header>
          <time dateTime={date}>{date}</time>
          <h2>{text}</h2>
        </header>
        <footer>
          <p><button type="button" onClick={() => handleClick('Header 1', 'Content 1', 'left', true)}>See Full Article</button></p>
        </footer>
        <Mydialog
          header={header}
          content={content}
          position={position}
          visibility={visible}
        />
      </div>
    </article>
  );
};

export default Article;

