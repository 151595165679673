import React from 'react';
import './card.css';
import Counter from '../counter/counter';

const Card = ({ maxCount, imgsUrl, desc }) => (
  <div className="cardCounter">
    <img
      className="card-image"
      src={imgsUrl}
      alt="Card"
    />
    <div className="card-caption">
      <Counter maxCount={maxCount} />
      <h4 className="card-description">
        {desc}
      </h4>
    </div>
  </div>
);

export default Card;
