import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/modules/effect-coverflow/effect-coverflow.min.css';
import 'swiper/modules/navigation/navigation.min.css';
import 'swiper/swiper.min.css';
import 'swiper/modules/pagination/pagination.min.css';

import { EffectCoverflow, Pagination, Navigation } from 'swiper';

import barclays from '../../assets/barclays.webp';
import aristocrat from '../../assets/Aristocrat.webp';
import cg from '../../assets/cg.webp';
import gs1 from '../../assets/gs1.webp';
import psa from '../../assets/psa.webp';
import ps1 from '../../assets/ps1.webp';
import chi from '../../assets/chi.webp';
import chi1 from '../../assets/chi1.webp';
import medline from '../../assets/Medline-logo.png';
import mugs from '../../assets/mugs.svg';
import apps from '../../assets/apps.svg';
import connection from '../../assets/connection.svg';
import creativeMinds from '../../assets/creativeMinds.svg';

import './carousel.css';
import Card from '../card/card';

function Carousel() {
  return (
    <div id="portfolio" className="container">
      <div className="gpt3__features-heading">
        <h1 className="gradient__text">Experience with Leading Companies</h1>
      </div>
      <div className="card_container">
        <Card maxCount={120} imgsUrl={apps} desc="Apps" />
        <Card maxCount={1500} imgsUrl={connection} desc="Connections" />
        <Card maxCount={100} imgsUrl={creativeMinds} desc="Innovative Minds" />
        <Card maxCount={3200} imgsUrl={mugs} desc="Coffee Mugs" />
      </div>
      <Swiper
        effect="coverflow"
        grabCursor
        centeredSlides
        loop
        slidesPerView="auto"
        coverflowEffect={{
          rotate: 0,
          stretch: 0,
          depth: 100,
          modifier: 2.5,
        }}
        pagination={{ el: '.swiper-pagination', clickable: true }}
        navigation={{
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
          clickable: true,
        }}
        modules={[EffectCoverflow, Pagination, Navigation]}
        className="swiper_container"
      >
        <SwiperSlide>
          <a href="https://home.barclays/" target="_blank" rel="noreferrer"><img src={barclays} alt="slide_image" /></a>
        </SwiperSlide>
        <SwiperSlide>
          <a href="https://www.aristocrat.com/" target="_blank" rel="noreferrer"><img src={aristocrat} alt="slide_image" /></a>
        </SwiperSlide>
        <SwiperSlide>
          <a href="https://www.capgemini.com/in-en/" target="_blank" rel="noreferrer"><img src={cg} alt="slide_image" /></a>
        </SwiperSlide>
        <SwiperSlide>
          <a href="https://www.medline.com/" target="_blank" rel="noreferrer"><img src={medline} alt="slide_image" /></a>
        </SwiperSlide>
        <SwiperSlide>
          <a href="https://www.gsam.com/content/gsam/global/en/homepage.html" target="_blank" rel="noreferrer"><img src={gs1} alt="slide_image" /></a>
        </SwiperSlide>
        <SwiperSlide>
          <a href="https://www.stellantis.com/en" target="_blank" rel="noreferrer"><img src={psa} alt="slide_image" /></a>
        </SwiperSlide>
        <SwiperSlide>
          <a href="https://www.commonspirit.org/" target="_blank" rel="noreferrer"><img src={chi} alt="slide_image" /></a>
        </SwiperSlide>
        <SwiperSlide>
          <a href="https://www.publicissapient.com/" target="_blank" rel="noreferrer"><img src={ps1} alt="slide_image" /></a>
        </SwiperSlide>
        <SwiperSlide>
          <a href="https://www.catholichealthinitiatives.org/" target="_blank" rel="noreferrer"><img src={chi1} alt="slide_image" /></a>
        </SwiperSlide>

        <div className="slider-controler">
          <div className="swiper-button-prev slider-arrow">
            <ion-icon name="arrow-back-outline"> </ion-icon>
          </div>
          <div className="swiper-button-next slider-arrow">
            <ion-icon name="arrow-forward-outline"> </ion-icon>
          </div>
          <div className="swiper-pagination"> </div>
        </div>
      </Swiper>
    </div>
  );
}

export default Carousel;
