import React, { useEffect, useState, useRef } from 'react';

function Counter({ maxCount }) {
  const [count, setCount] = useState(0);
  const counterRef = useRef(null);
  const isCountingRef = useRef(false);
  const hasStartedRef = useRef(false);

  useEffect(() => {
    const stopCounter = () => {
      isCountingRef.current = false;
    };

    const startCounter = () => {
      isCountingRef.current = true;

      const increment = Math.ceil(maxCount / 200); // Adjust the increment value for the desired speed
      let currentCount = 0;

      const updateCount = () => {
        if (currentCount >= maxCount) {
          currentCount = maxCount; // Set the count to the exact maximum count
          stopCounter();
          return;
        }

        currentCount += increment;
        setCount(currentCount);

        requestAnimationFrame(updateCount);
      };

      requestAnimationFrame(updateCount);
    };

    const handleScroll = () => {
      const elementPosition = counterRef.current.getBoundingClientRect().top;
      const windowHeight = window.innerHeight || document.documentElement.clientHeight;

      if (elementPosition < windowHeight && !hasStartedRef.current) {
        hasStartedRef.current = true;
        startCounter();
      } else if (elementPosition > windowHeight) {
        hasStartedRef.current = false;
        stopCounter();
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [maxCount]);

  return (
    <div ref={counterRef}>
      <h1 className="gradient__text">{`${count}+`}</h1>
    </div>
  );
}

export default Counter;
