import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
// import { Button } from 'primereact/button';
// import { Dialog } from 'primereact/dialog';
import Feature from '../../components/feature/Feature';
import './whatGPT3.css';
// import ImageTextExtractor from '../../components/imageToText/Imagetotext';

const WhatGPT3 = () => {
  const [visible] = useState(false);
  console.log(visible);
  // const [position, setPosition] = useState('center');
  // const footerContent = (
  //   <div>
  //     <Button label="No" icon="pi pi-times" onClick={() => setVisible(false)} className="p-button-text" />
  //     <Button label="Yes" icon="pi pi-check" onClick={() => setVisible(false)} autoFocus />
  //   </div>
  // );

  // const show = (pos) => {
  //   setPosition(pos);
  //   setVisible(true);
  // };

  return (
    <section className="gpt3__whatgpt3 section__margin" id="wgpt3">
      <Helmet>
        <title>Top Web Design &amp; Development Company in India</title>
        <meta name="description" content="Algodoor is a leading IT services and product company providing digital solutions for software development to clients globally. We specialize in Healthcare, Financial Services, Automobile, and Chatbots. Explore the possibilities beyond your imagination." />
        <meta name="keywords" content="IT services, Madhya Pradesh, top it companies in madhya pradesh, best it companies in india for freshers, best it companies, best it companies for freshers, best it companies in indore, Top IT Companies In Indore India, web development, software development, digital transformation, best IT company, Algodoor, IT services, software development, Healthcare, Financial Services, Automobile, Chatbots" />
        {/* Add other necessary meta tags for SEO */}
      </Helmet>
      <section className="gpt3__whatgpt3-feature">
        <Feature
          title="What is AlgoDoor"
          text="Algodoor is a leading strategic partner to companies around the world, leveraging technology to enable business transformation. We address the entire breadth of business needs, from strategy and design to managing operations. We draw on deep industry expertise and a command of the fast-evolving fields of cloud, data artificial intelligence, connectivity, software, digital engineering, and platforms."
        />
      </section>
      <section className="gpt3__whatgpt3-heading">
        <h2 className="gradient__text">The possibilities are beyond your imagination</h2>
        {/* <Button onClick={() => show('top')}>Explore the Library</Button> */}
      </section>
      <section className="gpt3__whatgpt3-container">
        {/* <Dialog header="Header" visible={visible} position={position} style={{ width: '50vw' }} onHide={() => setVisible(false)} footer={footerContent} draggable={false} resizable={false}>
          <ImageTextExtractor />
        </Dialog> */}
        <Feature
          title="UI/UX Design"
          text="Create visually appealing and user-friendly interfaces that enhance user satisfaction and engagement, with a focus on intuitive navigation, aesthetic design, and seamless interactions."
        />
        <Feature
          title="E-commerce Solutions"
          text="Build and optimize online stores with integrated shopping carts, secure payment gateways, inventory management, and personalized shopping experiences to boost sales and customer satisfaction."
        />
        <Feature
          title="Healthcare"
          text="Our software helps healthcare organizations streamline their operations and provide better healthcare."
        />
        <Feature
          title="Education"
          text="Transforming education with our comprehensive software solutions designed to enhance learning experiences and streamline administrative processes."
        />
        <Feature
          title="Financial Services"
          text="Empowering financial institutions with advanced software solutions that enable seamless integration, real-time data analysis, and personalized financial services, all backed by robust security measures."
        />
        <Feature
          title="Automobile Services"
          text="Implement cutting-edge connected car technology solutions, enabling features such as real-time vehicle tracking, remote diagnostics, predictive maintenance, and in-car infotainment systems."
        />
      </section>
    </section>
  );
};

export default WhatGPT3;
